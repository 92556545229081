import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCourses(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/courses', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addCourse(ctx, { formDataPayload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/courses/create', formDataPayload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addVideoCourse(ctx, { videoFormData, videoId, onLoading, signalController }) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`/courses/videos/${videoId}`, videoFormData, {
                        onUploadProgress: onLoading,
                        signal: signalController?.signal,
                        timeout: 0
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            })
        },
        fetchVideoCourseProgress(ctx, { videoId, signalController }) {
            return new Promise((resolve, reject) => {
                axios
                    .head(`/courses/videos/${videoId}`, { signal: signalController?.signal })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        cancelVideoCourseProgress(ctx, { videoId }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/courses/videos/${videoId}/progress`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchCourse(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/courses/${slug}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchInscriptions(ctx, { id, queryParams }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/courses/${id}/inscriptions`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        publicationCourse(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`/courses/${id}/publication`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateCourse(ctx, { id, formDataPayload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/courses/${id}/update`, formDataPayload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteCourse(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/courses/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}
